.tableprices {
  table {
    max-width: 100%;
    border-spacing: 0px 0;
    border-collapse: separate;
    color:black;
    & * {
      font-family: $font-primary !important;
    }
  }
  //Head
  th { 
    width: 16%;
    text-align: center;
    padding: $gutter/2;  
  }
  .tableprice__title-header {
    font-weight: normal;
    
    .tableprice__offre {
      font-size:  $font-size-base;
      font-family: $font-primary;
    }
  }
  .tableprice__title {
    font-size: $font-size-base;
    text-transform: uppercase !important;
    font-weight: bold !important;
  }
  .tableprice__highlight {
    span {
      font-size: 12px;
    }
  }
  //Body
  
  .title td {padding: 2rem 15px !important ;border-top: 1px solid rgba(0,0,0,0.15)}
  .title .titletext {font-size: 24px!important}
  .title td {background-color: #e5e5e5;}
  
  td:first-child {
    text-align: right;
    padding: $gutter/2;
  }
  td:not(:first-child) {
    padding: $gutter/2;
    //border-left: 1px solid rgba(0,0,0,0.15);
    border-right: 1px solid rgba(0,0,0,0.15);
    border-bottom: 1px solid rgba(0,0,0,0.15);
    font-size: $font-size-small;
  }
  td:first-child {
    border-left: 1px solid rgba(0,0,0,0.15);
    border-right: 1px solid rgba(0,0,0,0.15);
    border-bottom: 1px solid rgba(0,0,0,0.15);
}
  tr.even {

    td:first-child { 
      //background-color: $gray-lighter;
    }
  }
  
  .picto-coche {
    width: 40px;
  }
  
  tfoot {
    text-align: center;
    
    td:first-child {
        border: none !important;
    }
    
    td:not(:first-child) {
      padding: $gutter/4;
      border-bottom: none;
      & > * {
        color: white;
      }
      .price {
       
        text-transform: uppercase;
        border: none;
  
        &:hover {
          box-shadow: none;
        }
        &.price--through {
          text-decoration: line-through;
        }
        &:not(.price--through) {
          font-size: 22px;
        }
      }
      .price-suffix {
        //display: block;
        text-transform: none !important;
      }
      .price-remise {
        background-color: white;
        border-radius: 100rem;
        width: 90px;
        height: 90px;
        margin-left: auto;
        margin-right: auto;

        .remise {        
          font-size: 30px;
          padding-top: 5px;
        }
        .remise_date {
          margin-top: -8px;
          display: inline-block;
          line-height: 1;  

          .date_fin_label {
            font-size: 10px;
          }
          .date_fin {
            font-size: 12px;
          }
          

        }
      }
      .btn {
        margin-bottom: $gutter/2;
      }
      &.legend {
        border: none; 
        padding-top: $gutter*2; 
        & * {
          color: $color-txt1;
          padding-bottom: $gutter/2;
        }
      }
    }

  }
  .tease__offre {
    border: 4px solid $gray-lighter;
    margin-bottom: $gutter;
  }
}


.tease__offre {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.active {
    .offre__footer {
      .picto-coche {
        display: inline-block;
      }
      .btn.add-offre {
        display: none;
      }
    }
  }
  .offre__post-header {
    padding: $gutter/2;
    font-size: 30px;
    font-family: $font-primary;
  }
  .offre__header {
    text-align: center;
    padding: $gutter/2;
    display: flex;
    flex-wrap: wrap;
    margin: -4px;

    @include bp("md") {
      min-height: 225px;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    @include bp("lg") {
      padding: $gutter;
      min-height: 270px;
    }
    .offre__head {
      flex-basis: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width: 520px) {
        flex-basis: 50%;
        flex-grow: 1;
        text-align: left;
      }
      @include bp("md") {
        flex-basis: 100%;
        text-align: center;
      }
    }
    .offre__title {
      font-family: $font-primary;
      font-size: $font-size-h3;
      text-transform: uppercase;
    }
    .offre__subtitle1 {
      margin-top: 10px;
      @include bp("md") {
        margin-top: 0;
      }
    }
    .offre__price {
      flex-basis: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width: 520px) {
        flex-basis: 50%;
        flex-grow: 1;
        text-align: right;
      }
      @include bp("md") {
        flex-basis: 100%;
        text-align: center;
      }
    }
    .price {
      font-family: $font-primary;
      color: white;
      text-transform: uppercase;
      border: none;
      display: inline-block !important;

      &:hover {
        box-shadow: none;
      }
      &.price--through {
        text-decoration: line-through;
      }
      &:not(.price--through) {
        font-size: $font-size-h1;
      }
    }

    .price-suffix {
      display: inline-block !important;
      @media (min-width: 520px) {
        display: block !important;
      }
      @include bp("md") {
        display: inline-block !important;
      }
    }

    .price-percent-reduction {
      background-color: white;
      border-radius: 100rem;
      padding: $gutter/4 $gutter;
      flex-basis: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: $gutter;

      @include bp("md") {
        position: absolute;
        top: 5px;
        right: 5px;
        flex-basis: 115px;
        width: 115px;
        height: 115px;
        display: block;
        padding: 5px;
        margin-bottom: 0;
      }
      .reduction {
        font-size: $font-size-h1;
        .percent {
          display: inline;
          font-size: $font-size-base;
        }
      }
      .date_fin {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include bp("md") {
          font-size: 12px;
          margin-top: -5px;
        }
      }
    }

    .price-mention {
      font-size: $font-size-small;
      color: white;
    }
  }

  .offre__body {
    color: $color-primary;
    font-weight: initial;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .offre__content {
      max-width: 300px;
      padding-top: $gutter;
      margin-left: auto;
      margin-right: auto;
      flex-grow: 1;
    }
    .offre__description {
      font-family: $font-primary;
      list-style: none;
      margin-bottom: $gutter;
      padding-left: 0;

      > li:before {
        content: "";
        display: inline-block;
        height: 1em;
        width: 1em;
        background-image: url("../img/picto-petite-coche.svg");
        background-size: contain;
        background-repeat: no-repeat;
        padding-left: 2em;
      }
      ul {
        margin-top: 8px;
        li {
          list-style: disc !important;
          overflow: visible;
        }
      }
    }
    .offre__plus {
      padding-left: 45px;
      font-size: 18px;
      font-weight: 700;
      display: block;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .offre__freemium {
      font-size: 22px;
    }
    .offre__information_supp {
      padding: $gutter/2;
      font-style: italic;
      text-align: center;
      font-weight: bold;
      margin: 0 -4px;

      span {
        font-style: italic;
        font-weight: normal;
        display: block;
        color: $color-primary;
      }
    }
  }

  .offre__footer {
    text-align: center;
    padding: $gutter/2 0;

    @include bp("lg") {
      padding: $gutter 0;
    }
    .picto-coche {
      display: none;
      svg {
        width: 41px;
      }
    }
  }
}



.tarifs {
    & * {
        font-family: $font-primary !important;
        }
    margin-top: 69px;
    margin-bottom: 69px;
    padding-right: 0px;
    padding-left: 0px;
    border-style: none;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.13);
    background-color: #fff;
    box-shadow: none;
    text-align: center;
    }

    .table_line {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border-bottom: 1px solid #d8d8d8;
        font-size: 16px;
        line-height: 60px;
        }
        
        .table_line.double {
        height: 120px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #dbdbdb;
        }
        
        .table_line.fix {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 50;
        }
        
        .line_description {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 40%;
        height: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        text-align: right;
        }
        
        .line_description.title {
        font-size: 24px;
        font-weight: 700;
        }
        
        .line_data {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        }
        
        .line_data__col {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 50%;
        padding: 15px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        border-right: 1px none #d8d8d8;
        border-left: 1px none #d8d8d8;
        line-height: 26px;
        font-weight: 700;
        }
        
        .line_data__col.gratuit {
        margin-right: -1px;
        background-color: #86dd9e;
        }
        
        .line_data__col.first {
        margin-right: -1px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        line-height: 30px;
        }
        
        .line_data__col.abonnement {
        background-color: #a78b5c;
        color:white;
        }
        
        .offer_title {
        font-size: 22px;
        line-height: 30px;
        text-transform: uppercase;
        }
        
        .offer_title.black {
        color: #000;
        }
        
        .nobold {
        display: inline-block;
        margin-top: 5px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        }
        
        @media screen and (max-width: 991px) {
        .tarifs {
            padding-right: 0px;
            padding-left: 0px;
        }
        
        .table_line {
            height: auto;
            padding-top: 0px;
            padding-bottom: 0px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            border-bottom-style: none;
            line-height: 50px;
        }
        
        .table_line.double {
            height: auto;
            padding-top: 0px;
        }
        
        .table_line.odd {
            margin-top: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            background-color: #f1f1f1;
        }
        
        .table_line.fix {
            top: 90px;
        }
        
        .line_description {
            width: 100%;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-right: 1px none #000;
            border-bottom: 1px solid #dfdfdf;
            text-align: center;
        }
        
        .line_description.noshow {
            display: none;
        }
        
        .line_data {
            width: 100%;
            border-bottom: 2px solid #aaa;
        }
        
        .line_data__col.first {
            border-right: 1px solid #dfdfdf;
        }
        }
        
        @media screen and (max-width: 767px) {
        .table_line.fix {
            top: 140px;
        }
        
        .line_description {
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
        }
        
        @media screen and (max-width: 479px) {
        .tarifs {
            margin-right: -15px;
            margin-left: -15px;
        }
        
        .line_description {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 0px;
            padding-right: 27px;
            padding-left: 27px;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            color: #5e5e5e;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            text-transform: uppercase;
        }
        
        .line_description.noshow {
            display: none;
        }
        
        .line_data {
            font-size: 16px;
            line-height: 50px;
        }
        
        .offer_title {
            padding-right: 10px;
            padding-left: 10px;
            font-size: 18px;
            line-height: 20px;
        }
        
        .nobold {
            text-transform: none;
        }
        }
        
        