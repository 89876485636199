.header {
      position: fixed;
      width: 100%;

      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
	justify-content: space-between;
	align-items:stretch;

      background-color: #FFF;
      border-bottom: none;

      z-index : 1000;

      @media (min-width: $screen-sm) {
            flex-wrap: nowrap;
            border-bottom: solid $color-varied 1px;
      }


      .header__logo {
            order: 0;
		width: 225px;
            padding: 0 10px;
            border-right: none;
            margin: 0 auto;
            flex-basis: 40%;

            @media (min-width: $screen-sm) {
                  border-right: solid $color-varied 1px;
                  flex-basis: auto;
            }
      }

      .header__search {
            width: 500px;
            flex-grow: 2;
            order: 2;
      	text-align: left;
            margin: 0 auto;
            z-index:0;

            @media (min-width: $screen-sm) {
                  flex-grow: 1;
                  order: 1;
                  text-align: left;
            }
      }

      @media screen and (max-width: 768px) {
            .menu_header{
                  display:none!important;
            }
          }
      
      .header__menu {
            color: black;
            order: 1;
            position: relative;
            width: 100%;
            flex-basis: 50%;

            @media (min-width: $screen-sm) {
                  flex-shrink: 1;
                  order: 2;
                  width: auto;
                  //flex-basis: auto;
            }

            .menu_header{
                  display: flex;
                  margin: 12px auto;
                  justify-content: space-around;
                 .menu_header-link{
                        align-self: center;
                        margin: 20px 10px;
                        a{
                              color: black;    
                        }
                        
                 }
            }
            .header__cart {
                  position: absolute;
                  top: 88px;
                  right: 0;
                  padding: 10px;
                  background-color: #FFF;
                  box-shadow: 0px 6px 5px 0px $color-bg;
                  display: none;
                  transition: all 1s ease;
            }


            .header__cart--active {
                 display: block;
            }


            .navbar {
                  @media (min-width: $screen-sm) {
                        display: none;
                  }
                  height: 100%;

                  .header__items {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: stretch;

                    height: 100%;

                    .header__burger {
                          align-self: center;
                          padding: 0 10px;

                          @media only screen and (min-width : $screen-sm) {
                                padding: 0 25px;
                          }
                    }

                    .header__link {
                          align-self: center;

                          font-family: $font-secondary;
                          font-size: 16px;
                          font-weight: 500;
                          color: $color-txt1;
                          padding: 0 10px;

                          @media only screen and (min-width : $screen-sm) {
                                padding: 0 25px;
                          }

                          &:hover {
                                color: $color-ternary;
                                text-decoration: none;
                          }
                    }

                    .header__link--menu {
                          display: none;
                          float: left;

                          @media only screen and (min-width : $screen-sm) {
                                display: block;
                          }
                    }



                    .header__link--connect {
                      display: none;

                      @media only screen and (min-width : $screen-sm) { 
                            display: block;
                      }

                    }
                    
                    .header__link--boutique {
                      display: none;
                    
                      @media only screen and (min-width : $screen-sm) {
                            display: block;
                      }
                    
                    } 
                  
                  }

            }
      }
}











