/* Didomi YouTube placeholder */
.gdpr-video-placeholder {
  transition: box-shadow 0.6s ease, background-color 0.6s ease;
  background: #ededed;
  box-shadow: 0 0 0 3px #c4c4c4 inset;
  clear: both;
  color: #59595b;
  display: block;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  p {
    font-size: 80%;
    }
}

.gdpr-video-placeholder:hover {
  background-color: #f8f8f8;
  box-shadow: 0 0 0 3px $color-primary inset;
}

.gdpr-video-placeholder__inner {
    padding: 1em;
}

.gdpr-video-placeholder__inner a {
  color: #919191;
  font-weight: normal;
  text-decoration: underline;
}

.gdpr-video-placeholder__inner a:hover {
  text-decoration: none;
}

.gdpr-video-placeholder button {
  transition: background-color 0.3s ease;
  background: $color-primary;
  border: 0;
  color: white;
  cursor: pointer;
  line-height: 1;
  padding: 0.8em 1.5em;
}

.gdpr-video-placeholder button:hover,
.gdpr-video-placeholder button:focus {
  background-color: $color-primary;
}

@media screen and (min-width: 1200px) {
  .gdpr-video-placeholder {
    
    p {
        font-size: 1em;
    }
  } 
}