@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: $screen-sm-max) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: $screen-md-max) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

/**********************************/
/******       MENU       **********/
/**********************************/
.header-boutique {
	background-color: $color-primary;
	.header__logo{
		border-right: none;
		img {
			padding: 10px 0;
		}
	}
	.container {	
		@media (max-width: $screen-xs-max) {
			width: 100%;
		}
	}
}
#navbar-boutique {
	a {
		color: white;
		font-weight: normal;
	}
	.header__links {
		width: 100%;
    display: flex;
    flex-direction: row;
		justify-content: flex-end;
		.fa {
			padding-right: $gutter-quarter;
		}
	}
	.hamburger, 
	.hamburger::before,
	.hamburger::after
	{
		background-color: white;
	}
	 
}

/**********************************/
/******    PAGE PRODUCT  **********/
/**********************************/
.post-type-product-boutique {
	.single-product-details {		
		margin-top: $gutter;
		padding: 0 $gutter;		
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		@media (min-width: $screen-sm)  {
			border-top: 1px solid $color-varied; 
		}
		@media only screen and (max-width : $screen-md-max) {
			margin-top: 0;
			padding: 0;
		}
		
	}

	.product-details {
		text-align: center;
    padding-bottom: $gutter-double;
    .product-description{
      text-align: left;
    }
	}
	 .entry-title {
		text-transform: uppercase;
		color: black;
    margin-bottom: $gutter;
    font-size: 30px;
	
		@media (max-width: $screen-xs-max) {
			margin-top: 0;		
		}
	}
	.product-image img {
    width: 100%;
    @media (max-width: $screen-sm-max) {
			max-width: 350px;		
    }
   
  }
  
	.product-description {
    padding: 1rem;
    padding-top: 0;
    margin-bottom: $gutter-quarter;
    font-size: 14px;

		@media (max-width: $screen-xs-max) {
			margin-bottom: 0;			
    }
    @media (min-width: $screen-lg) {
			min-height: 225px;			
		}
	}
  .product-addtocart a.button,
  #respond input#submit.alt, 
  a.button.alt, 
  button.button.alt, 
  input.button.alt {
		background: white !important;
		color: black !important;
		border-radius: 0;
		font-weight: normal;
    border: 2px solid $color-primary;
    width: 80%;
		padding: $gutter-half;
		margin-bottom: $gutter;
	}
  .product-addtocart a.button:hover,
  #respond input#submit.alt:hover, 
  a.button.alt:hover, 
  button.button.alt:hover, 
  input.button.alt:hover {
		background: $color-primary!important;
		color: white !important;
	}
	.product-price {
		font-size: $font-size-h3;
		font-weight: bold;
	}
}

/**********************************/
/******    PAGE GIFT CARDS  *******/
/**********************************/
.post-type-product-boutique.type--gift {
  h1 {
    margin-top: 20px;
  }
  .product-image {
    margin-bottom: $gutter-half;
  }
  .product-description {
    margin-bottom: $gutter-double;
    padding-top: 0;
  }

  .ywgc-template, 
  .product_meta{
    display: none;
  }
}
.gift-cards_form {
  text-align: left;
  input, textarea {
    padding-left: 5px;
  }
  #gift_amounts {
    background-color: white;
    line-height: 30px;
    height: 30px;
    //margin-left: $gutter-quarter;
    width: 35%;
  }
  #gift_card_rq {
    font-weight: normal;
    font-style: italic;
  }
  #gift_amounts:focus, 
  input:focus,
  textarea:focus,
  option:focus {
    border: 2px solid $color-primary!important;
    outline-style: none;    
  }
  .gift-cards-list {
    width: 97%;
  }
  .ywgc-amount-label {
    width: 15%;
  }
  .ywgc-amount-value {
    width: 85%;               
  }
  #ywgc-manual-amount {
    width: 65%;
  }
  .ywgc-single-recipient {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .yith_wc_gift_card_input_recipient_details {
    width: 48%;
    display: block;
    margin-right: 1%;
  }
  a.add-recipient, 
  a.ywgc-remove-recipient {
    color: $color-primary;
  }
  #ywgc-sender-name,
  textarea
  {
    width: 97%;
  }
  .screen-reader-text {
    width: auto;
    height: auto;
    position: static !important;
    overflow: visible;
    margin: 0;
    margin-right: $gutter-quarter;
  }
  button.button.alt{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: $gutter;
  }
}

@media (max-width:1199px) {
  .gift-cards-list .ywgc-amount-label {
    width: 20%;
  }
  .gift-cards-list .ywgc-amount-value {
    width: 80%;
  }
  .gift-cards-list .ywgc-amount-value #gift_amounts,
  .gift-cards-list .ywgc-amount-value #ywgc-manual-amount {
    width: 100%;
    margin-top: 5px;
  }
  #gift_amounts {
    margin-left: 0; 
  }
  .post-type-product-boutique.type--gift {
    h1 {
      margin: 0;
      margin-bottom: 1rem;
    }
    .product-description {
        padding: 0 $gutter;
        margin-bottom: 1rem;
    }
  }
}
/**********************************/
/******    PAGE PRODUCTS **********/
/**********************************/
.archive-boutique {
  
  .category-title,
  .category-description {
		text-align: center; 
  }
  .wrapper--products{
    margin-top: $gutter;
    margin-bottom: $gutter;
  }
	.product{
		flex-grow: 0;
		text-align: center;
		margin-bottom: $gutter;
		display: flex;
		flex-direction: column;
		cursor: pointer;
	}
	.product-image {
		
		img {
			width: 100%;
		}
	}
	.product-title {
		text-transform: uppercase;
		margin-top: $gutter-half;
    margin-bottom: $gutter-eighth;
    flex-grow: 1;
		
		a {
      color: black;
      span {
        text-transform: initial;
        font-style: italic;
      }
		}
		a:hover {
      text-decoration: none;
      color: $color-ternary;
		}
	}
	.product-price {

		font-weight: bold;
	}
} 

