// BUTTONS MIXINS

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;
  transition: all 0.25s;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 25%);
  }
  &:hover {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);
    @include shadow(2);
  }
  &:active,
  &.active {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled] {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
          border-color: $border;
    }
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}



// BUTTONS CLASSES


// BASE

.btn {
    display: inline-block;
    font-family: $font-secondary;
    font-size: 13px;
    color: #FFF;
    margin-bottom: 0; // For input.btn
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    text-decoration : none;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    white-space: nowrap;
    text-transform: uppercase;
    padding: 10px 50px;

    &:hover,
    &:focus,
    &.focus {
        color: $color-primary;
        text-decoration : none;
        background-color: $color-ternary;
    }
}

// BUTTONS COLORS

.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}
.btn-primary {
	background-color: $color-primary;
  border: 1px solid $color-primary;
    &:hover {
        color: #FFF;
        background-color: $color-primary-hover;
    }
}

.btn-secondary {
	background-color: $color-secondary;

    &:hover {
        color: #FFF;
        background-color: $color-secondary-hover;
    }
}

.btn-ternary {
	background-color: $color-ternary;

    &:hover {
        color: #FFF;
        background-color: $color-ternary-hover;
    }
}


.btn-light {
    color: $color-txt1;
    background-color: #FFFFFF;
}

.btn-fwhite {
  color: $color-primary;
  background-color: #FFFFFF;
  border: 1px solid $color-primary;
  &:hover {
    color: #FFF;
    background-color: $color-primary;
  }
}
.btn-swhite {
  color: white;
  background-color: $color-primary;
  border: 1px solid white;
  &:hover {
    color: $color-primary;
    background-color: white;

  }
}
// HOLLOW BUTTONS

.btn-hollow {
    background-color: transparent;
    border-width: $gutter-eighth;

    &:hover {
        background-color: transparent;
    }

    &.btn-primary { color: $color-txt1; }
    &.btn-secondary { color: $color-txt1; }
    &.btn-ternary { color: $color-txt1; }
    &.btn-light { color: $color-txt1; }

}

.btn-generic {
    opacity: 1;

    &:hover {
        opacity: 0.8 !important;
        text-decoration: none;

    }
}
.btn-success {
  background-color: $color-success !important;

  &:hover {
    background-color: darken($color-success, 10%) !important;
  }
}
.btn-info {
  background-color: $color-info !important;;

  &:hover {
    background-color: darken($color-info, 10%) !important;;
  }
}
.btn-warning {
  background-color: $color-warning !important;;

  &:hover {
    background-color: darken($color-warning, 10%) !important;;
  }
}
.btn-danger {
  background-color: $color-danger !important;;

  &:hover {
    background-color: darken($color-danger, 10%) !important;;
  }
}

.btn-wrap {
  white-space: normal;
  word-wrap: break-word;
}
// BUTTON SIZES

.btn-lg {
    // line-height: ensure even-numbered height of button next to large input
    @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $btn-border-radius-large);
}
.btn-md {
  padding: 10px 25px;
} 
.btn-sm {
    // line-height: ensure proper height of button next to small input
    @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}

.btn-xs {
    @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}


// BLOCK BUTTONS

.btn-block {
    display: block;
    width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: $gutter-quarter;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
