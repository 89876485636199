// ****************************
//      Global
// ****************************

div,span,p {
	font-family: $font-secondary;
	font-size: 16px;
}

h1 {
	font-family: $font-primary;
	font-weight: 300;
	font-size: 24px;
	color: $color-primary;
	margin: 35px 0 25px 0;
	padding:0;

	@media only screen and (min-width : 768px) {
		font-size: 36px;
	}
}

h2 {
	font-family: $font-primary;
	font-weight: 300;
	font-size: 18px;
	color: $color-primary;
	margin: 35px 0 25px 0;
	padding:0;

	@media only screen and (min-width : 768px) {
		font-size: 24px;
	}
}

h3 {
	font-family: $font-primary;
	font-weight: 300;
	font-size: 16px;
	color: $color-primary;
	margin:0 0 20px 0;
	padding:0;

	@media only screen and (min-width : 768px) {
		font-size: 22px;
	}
}

a {
	color: $color-ternary;
	text-decoration: none;
}

.txt {
	font-size: 16px;
	color: $color-txt2;
}

.headeroffset {
	position: relative;
	width: 100%;
}

.breadcrumb {
	padding: 8px;
	color: $color-txt1;

	span {
		font-size: 12px;
	}

	a {
		color: $color-txt1;
		font-size: 12px;
	}
}

.owl-carousel {
	position: relative;

	.owl-dots {
		position: absolute;
		bottom : 20px;
		left: 48%;
	}

	.owl-nav {
		background-color: transparent;

	    .owl-prev {
	        position: absolute;
	        top: 40%;
	        left: -6%;
	        border: solid transparent 0px;
	    }

	    .owl-next {
			position: absolute;
	        top: 40%;
	        right: -6%;
	        border: solid transparent 0px;
	    }
	}

	.owl-nav [class*=owl-] {
		background: transparent;
	}
}

.clear {
	visibility: hidden;
	clear: both;
}


// ****************************
//      Secondary blocks
// ****************************


.map {
	width: 100%;
	height: 400px;
  border: #ccc solid 1px;
  
}

/* fixes potential theme css conflict */
.map img {
	max-width: inherit !important;
}
/* Affiche l'icone de fermeture */
img[src*="gstatic.com/"], 
img[src*="googleapis.com/"] {
  max-width: none !important;
  max-height: none !important;
}
.map--single--vineyards {
	margin-bottom: 100px;
}

.picto {
	width: 85px;
	padding: 2px;
	text-align: center;

	.picto__image {
		text-align: center;
		margin: 0 auto;
	}

	.picto__txt {
		font-family: $font-secondary;
		font-size: 14px;
		text-align: center;
		line-height: 1.4;
		margin-top: 5px;
	}
}

.picto--wine {
	.picto__txt {
		color: $color-secondary;
	}
}

.picto--activity {
	.picto__txt {
		color: $color-secondary;
	}
}

.picto--service {
	.picto__txt {
		color: $color-secondary;
	}
}

.picto--farming {
	.picto__txt {
		color: $color-farming;
	}
}

.picto--price {
	.picto__txt {
		color: $color-txt1;
	}
}




.advantages {

	position: relative;
	background-color: $color-bg;
	padding: 40px 0;

	.advantages__title {
		margin-bottom: 35px;
		text-align: center;
	}

	.advantages__item {

		.advantages__image {
			display: block;
			margin: 0 auto 15px auto;
		}

		.advantages__label {
			font-family: $font-secondary;
			font-size: 16px;
			color: $color-primary;
			line-height: 1.2;
			font-weight: 500;
			text-align: center;
			margin-bottom: 20px;

			span {
				display: inline;
				padding-right : 4px;

				@media only screen and (min-width : 768px) {
					display: block;
					padding-right : 0px;
				}
			}
		}
	}
}



.godfather {

	position: relative;
	margin: 20px 0;

	.godfather__borders {
		border : solid $color-bg 2px;

		.godfather__left {

			min-height: 200px;
			
			.godfather__video {
				position: relative;
				padding-bottom: 56.25%;
				padding-top: 30px;
				height: 0;
				overflow: hidden;
					
				iframe, object, embed {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
		
			}
			
			
		}

		.godfather__right {
			display : flex;
			flex-direction : column;
		    flex-wrap : wrap;
		    justify-content: space-between;
		    align-items : center;

			text-align: center;

			.godfather__logo {
				text-align: center;
				margin: 20px auto 0 auto;
			}

			.godfather__quote {
				width: 60%;

				.godfather__quoteimg {
					text-align: left;
				}

				.godfather__quotetxt {
					width: 90%;
					color: $color-secondary;
					text-align: center;
					line-height: 1.2;
					margin: 0 auto 30px auto;

					span {
						display: inline;
						padding-right: 4px;
						font-family: $font-primary;
						font-size: 25px;


						@media only screen and (min-width : 768px) {
							display: block;
							font-size: 32px;
							padding-right: 0px;
						}
					}
				}
			}

			.godfather__author {
				width: 90%;
				font-family: $font-secondary;
				color: $color-secondary;
				text-align: right;
				margin: 0 auto 20px auto;
				line-height: 1.2;

				span {
					display: inline;
					font-size: 10px;
					margin-left: 4px;

					@media only screen and (min-width : 768px) {
						display: block;
						font-size: 16px;
						margin-left: 0;
					}
				}
			}
		}
	}
}

.wrapper__iframe {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
		
	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

}
.discover {

	position: relative;
	margin: 20px 0;

	.discover__left {
		font-family: $font-primary;
		font-size: 24px;
		font-weight: 300;
		color: $color-primary;
		text-align: center;
		line-height: 1.2;
		padding: 10px;

		@media only screen and (min-width : 768px) {
			font-size: 34px;
			text-align: right;
		}
	}

	.discover__right {
		font-family: $font-secondary;
		font-size: 12px;
		color: $color-txt2;
		padding: 10px;
	}

	.discover__more {
		margin: 25px auto 35px auto;
		text-align: center;

		@media only screen and (min-width : 768px) {
			margin: 25px 0 100px 0;
			text-align: right;
		}
	}
}


.infos {
	position: relative;
	padding-top: 35px;
	background-color: $color-bg;

	.infos_title {
		font-family: $font-primary;
		font-weight: 500;
		font-size: 20px;
		color: $color-primary;
		margin-bottom: 8px;
		text-align: center;

		@media only screen and (min-width : 768px) {
			text-align: left;
		}
	}

	.infos_title--contact {
		margin-top: 20px;

		@media only screen and (min-width : 768px) {
			margin-top: 0;
			text-align: center;
		}
	}

	.infos__newsletter {
		label {
			display: none;
		}

		.gform_wrapper {
			margin-top: 20px;

			form {
				display : flex;
				flex-direction : row;
			    flex-wrap : wrap;
			    justify-content : flex-start;
			    align-items : center;

				.gform_body {
					flex: 3;

					.gfield {
						margin: 0;
						padding: 0;
					}

					ul {
						list-style: none;
						width: 100%;						
						margin: 0;
						padding: 0;
					}

					.ginput_container_email {
						margin-top:0;

					}

					input {
						width: 100%;
						height: 35px;
						border: solid $color-secondary 1px;
					}
				}

			    .gform_footer {
			    	position: relative;
					flex: 1;
					padding: 0;
					margin: 0;
					margin-top: auto;
    				align-self: flex-end;

					input[type=submit] {
						margin: 0;
						height: 35px;
						color: #FFFFFF;
						padding: 0 15px;
						background-color: $color-secondary;
						border: solid $color-secondary 1px;
					}

					input:hover {
						background-color: $color-secondary-hover;
						border: solid $color-secondary-hover 1px;
					}

					.gform_ajax_spinner {
						position: absolute;
						top:0;
						right:-26px;
					}



			    }
			}
		}
	}

	.infos__socialbtns {
		text-align:center;

		.infos__socialbtn {
			text-align: center;
			margin-right: 5px;
		}
	}

	.infos_title--partners {
		margin-top: 20px;

		@media only screen and (min-width : 768px) {
			text-align: center;
		}
	}

	.infos__partners {
		text-align: center;

		img {
			margin: 5px;  
      max-height: 60px;
    }
    @media (max-width : 480px) {
			.infos__socialbtn {      
        display: block;
        padding-top: 15px;
      }
		}
    @media (min-width : 768px) and (max-width: 991px) {
			.infos__socialbtn:nth-child(2):after {
        content: " ";
        display: block;
        padding-top: 15px;
      }
		}
	}

	.infos__links {
		margin-top: 35px;
		text-align: center;
		border-top: solid $color-varied 2px;
		padding: 30px 0;

		.infos__link {
			color: $color-txt1;
			text-align:center;
		}
	}
}


.portrait {
	.portrait__content {

		position: relative;
		padding: $padding-content;
		border: solid $color-varied 1px;
		margin-bottom: 40px;


		.portrait__image {
			width: 80px;
			border-radius: 50%;
			margin-bottom: 30px;
			z-index: 1000;
		}

		.portrait__title {
			font-family: $font-primary;
			font-size: 16px;
			font-weight: 500;
			color: $color-txt1;
			margin-bottom: 30px;
		}

		.portrait__description {
			font-family: $font-secondary;
			font-size: 16px;
			font-weight: 300;
			color: $color-txt2;
			margin-bottom: 20px;
		}
	}
}



.errorpage {
	position:relative;
	margin: 20px 0 50px 0;
	background: #f2f2f2 url('/wp-content/themes/lightwords/dist/img/error404.jpg') no-repeat top left;
	background-size: cover;
	min-height:400px;

	@media (min-width: 768px) {
		min-height:750px;
	}

	.errorpage__texte {
		position: absolute;
		left: 20px;
		bottom: 20px;
		padding: 10px;
		background-color: #FFF;

		.errorpage__title {
			font-family: $font-primary;
			font-size: 32px;
		}

		.errorpage__content {
			font-family: $font-primary;
			font-size: 16px;
		}
	}

	&:after {
		clear:both;

	}

}










.wrapper--header,
.wrapper--pre-header {
	background-color: $gray-lighter;
}

.wrapper--footer,
.wrapper--post-footer,
.wrapper--pre-footer {
	background-color: $gray-lighter;
}

.site-name {
	font-weight: 900;
	color: $brand-primary;
}

.logo {
	display: inline-block;
	width: 2.5rem;
	height: 2.5rem;
	vertical-align: text-bottom;
	fill: $brand-primary;
}

.footer-copyright {
	padding: 1.5rem 0;
	text-align: center;
	font-weight: 500;
	color: $gray;
}

blockquote {
	font-style: italic;
	color: $gray;
	border-color: $brand-primary;
}

.widget ul {
	list-style-type: none;
	padding-left: 0;
}


.header_image {
	height: 15vw;
	background-size: cover;
	background-position: center center;
	margin-bottom: $gutter;
}





// Margin top 0
.mt0 {
	margin-top: 0;
}

// WooCommerce tabs
.tabs {
	list-style: none;
	margin-top: 2em;
	padding-left: 0;

	li {
		display: inline-block;
		a {
			padding: 0.5em 1em;
			background: $gray-dark;
			color: $gray-lighter;
		}
		&.active {
			a {
				background: $brand-primary;
			}
		}
	}
}

.container img {
	max-width: 100%;
	width: auto;
	max-height: 100%;
	height: auto;
}




// Menu avec panier
.cart_list_menu {
	width: 20vw;
	padding: 1em;
	font-size: $font-size-small;
}
li:hover > ul.dropdown-menu {
	display: block;
}

.dropdown-menu > li.mini_cart_item > a {
	white-space: initial;
	padding: 0px;

	img {
		float: none !important;
		display: block;
	}
}

h1.product_title {
	color: $brand-secondary;
}
.h2 {
	font-weight: 400;
}
.wrapper--posts {
	article.tease-product {
		margin-bottom: 1.5em;
	}
}
.tease-product {
	display: flex;
	flex-direction: column;

	.offer_body {
		flex-grow: 1;
	}
	.offer__pictos {
		display: flex;
		flex-direction: wrap;
		flex-wrap: wrap;
		margin-bottom: 1.5rem; 
	}
}

.flexrow, form.contact-form ul.gform_fields, form.contact-form ul.gform_fields li.gfield:nth-child(1) ul.gfield_checkbox {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}


.galerie-viticulteur {
	background: $gray-lighter;
	margin: $gutter 0;

	.galerie-image-container {
		text-align: center;
		padding: $gutter-eighth;
	}
}


/* Override titre page erreur */
.error404 {
	h1 {
		display: none;

	}
}

/* Override commentaires article */
.wrapper--comments {
	margin:0 !important;
	padding:0;

}
.respond {
	background-color:$color-bg;
	padding: 10px;
	margin-bottom: 20px;
}


.noappelations {
	padding:10px;
	color:$color-ternary;
	border:solid $color-ternary 1px;
	text-align:center;
}


/* Page CGV */
#post-576 h1{margin-bottom:40px;}
#post-576 h3 {margin-top:25px;}

#post-576 {
	div, span, p {
		font-weight:300;
		font-size:1.8rem;
	
	}
}


.arrow-up {
	
	position: fixed;
	bottom: 2rem;
	right: 2rem;
	z-index: 99;
	border: none;
	background: url('../img/arrows.png') no-repeat center center;
	background-color: $color-primary;
	width: 32px;
	height: 32px;	
	cursor: pointer;
	outline: 0;
	padding: 25px 35px;
	border-radius: 2px;

	@media only screen and (max-width : 480px) {
		display:none !important;
	}
}

.related.products {
	display: none;
	}

.acf-form--account-viticulteur {
  margin-bottom: 3rem;
	.button {
		height: auto !important; 
	}
}

.align-center {
	text-align: center;
}
.gfield_checkbox--appellations li{
	display: none;
}
.gfield_checkbox--appellations li label{
	padding-left: 5px!important;
}

.gfield_checkbox--cepages li{
	display: none;
}
.gfield_checkbox--cepages li label{
	padding-left: 5px!important;
}

.formpage__title{
	text-align: center;
}

.filter_cat {
	display: flex;
    flex-wrap: wrap;
    gap: 3px;

	a {
		flex: 1 0 250px;
	}
}