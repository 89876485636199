.section--history {
  background-color: #e3e7ea;
  background-image: url("../img/histoire-oenotourisme.jpg");
  background-position: bottom right;
  background-repeat: no-repeat;
  height: 600px;
  @media (max-width : 767px) {
    background-position: bottom right;
    background-image: url("../img/histoire-oenotourisme2.jpg");
    background-size: contain;
    height: 860px;
  }
  @media (min-width : 768px) {
    background-position: bottom right -95px;
    height: 965px;
  }
  @media (min-width : 992px) {
    background-position: bottom right;
    height: 900px;
  }
  @media (min-width : 1200px) {
    background-position: bottom right -100px;
    height: 600px;
  }
  @media (min-width : 1600px) {
		background-position: bottom right;
  }
}

.history {
  width: 100%;
  padding-top: 50px;

  
  @media (min-width : 1200px) {
		width: 36%;
  }
  @media (min-width : 1600px) {
		width: 45%;
  }
}
.section--team {
  padding-top: 50px;
}
.team-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.team {
  flex-basis: 100%;
  text-align: center;
  margin-bottom: 30px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  @media (min-width : 750px) {
    flex-basis: 50%;
  }
  @media (min-width : 992px) {
    flex-basis: 33.33%;
  }
  @media (min-width : 1600px) {
    margin-bottom: 80px;
  }
  .team__content {
    flex-grow: 1;
  }
  .team__name {
    font-weight: 400;
    margin: 0;
  }
  .team__role{
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 300;
  }
  .team__photo img {
    border-radius: 100%;
    border: 4px solid #e3e7ea;
    margin: 20px;
  }
  .team__coordonnees {
    font-weight: 900;
    margin: 10px 0; 
  }
  .team__description{
    font-size: 14px;
  }
}