/* SURCHARGE WOO-COMMERCE */

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
    color: #FFFFFF;
    background-color: #f57751;
}

.woocommerce input#submit:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover {
	color: #FFFFFF;
    background-color: #a74427;
}

.woocommerce-variation-add-to-cart {
	float: right;
}

.woocommerce .article-body form.cart button.button {
	margin: 0 0 auto auto;
}

/* SURCHARGE CALENDAR */
// On retire les prix du calendrier
.DOPBSPCalendar-day .dopbsp-bind-middle .dopbsp-body .dopbsp-price {
	display: none;
}

.DOPBSPCalendar-navigation .dopbsp-add-btn {
    display: none !important;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background:  $color-secondary !important;
}


.article-body form.cart {
	text-align: right;
}

/**
 * Star ratings
 */
.star-rating {
	float: right;
	overflow: hidden;
	position: relative;
	height: 1em;
	line-height: 1;
	font-size: 1em;
	width: 5.4em;
	font-family: 'star' !important;;
	span {
		overflow: hidden;
		float: left;
		top: 0;
		left: 0;
		position: absolute;
		padding-top: 1.5em;
	}

	span:before {
		content: "\53\53\53\53\53";
		font-family: 'star' !important;
		top: 0;
		position: absolute;
		left: 0;
	}
}



.woocommerce-Price-currencySymbol {
	padding-left: 4px;
}


.woocommerce-page #payment .wc-terms-and-conditions.wc-terms-and-conditions {
	padding-left:30px;
}

.woocommerce form .form-row .input-checkbox {
	margin: 10px 0 0 -20px;
}

.woocommerce-notice--success {
	padding:10px;
	color:#1fab1f;
	border:solid #1fab1f 1px;
	margin-bottom:20px;
}





