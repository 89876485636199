.offers {
	margin-bottom: 100px;
}

.offer--list {
	position: relative;
	border: solid $color-varied 1px;
	padding: $padding-content;
	margin-bottom: 15px;

	.offer__header {
		.offer__title {
			font-family: $font-primary;
			font-weight: 700;
			font-size: 16px;
			color: $color-secondary;
			margin: 0;
		}

		.offer__subtitle {
			font-family: $font-primary;
			font-weight: 700;
			font-size: 16px;
			color: $color-txt1;
		}
	}

	.offer__description {
		font-family: $font-secondary;
		font-size: 16px;
		color: $color-txt2;
		font-weight: 300;
		margin-bottom: 15px;

		li {
  			font-style: italic;
		}
	}

	.offer__details {
		font-family: $font-secondary;
		font-size: 16px;
		color: $color-txt2;

		.offer__pictos {
			display : flex;
			flex-direction : row;
		    flex-wrap : nowrap;
		    justify-content : flex-start;
		    align-items : center;
		}

		.offer__price {
			font-weight: 700;
			text-align: center;
		}

		.offer__book {
		}
	}
}

.offer--article {

	.offer__details {
		margin-bottom: 50px;

	}

	.offer__pictos {
		position: relative;
		width: auto;

		border: solid $color-secondary 1px;
	    padding: $padding-content;


		.picto {
			float: left;
		}

		&:after {
				clear: both;
			}
	}

	&:after {
			clear: both;
		}

}

.offers__content {
	iframe {
		border: 1px solid #CCCCCC;
	}
}