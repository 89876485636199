.opinions {
	margin-bottom: 40px;
}

.opinion {
	position: relative;
	border: solid $color-varied 1px;
	padding: $padding-content;
	margin-bottom: 15px;

	.opinion__author {
		font-family: $font-secondary;
		font-size: 16px;
		color: $color-txt2;
		font-weight: 500;
		text-transform: uppercase;
	}

	.opinion__date {
		font-family: $font-secondary;
		font-size: 16px;
		color: $color-txt2;
	}

	.opinion__ranking {
		color: $color-ternary;
		font-size: 24px;

		span {
			font-size: 45px;
		}
	}

	.opinion__description {
		font-family: $font-secondary;
		font-size: 16px;
		color: $color-txt2;
	}





}
