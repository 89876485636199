.footer {
	background-color: $color-primary;
	padding: 20px 0;

	.footer__logo {
		margin: 10px auto;
		text-align: center;

		@media only screen and (min-width : 768px) {
			margin: 20px 0;
			text-align: left;
		}
	}

	.footer__bottom {
		display : flex;
		flex-direction : row;
	    flex-wrap : wrap;
	    justify-content : space-between;
	    align-items : stretch;
	   	margin-bottom: 10px;
	   	margin-top: 20px;

		@media only screen and (min-width : 768px) {
 			flex-wrap : nowrap;
			text-align: left;
			margin-top: 0;
		}


		.footer__copyright {
			order: 1;
			flex-basis:100%;

			color: #FFFFFF;
			text-align: center;
			margin-bottom: 20px;

			@media only screen and (min-width : 768px) {

				flex-basis: auto;
				text-align: left;
			}
		}

		.footer__webcd {
			order: 3;
			flex-basis:100%;

			color: #FFFFFF;
			text-align: center;
			margin-bottom: 20px;

			@media only screen and (min-width : 768px) {
				order: 2;
				flex-basis: auto;
				text-align: left;
			}

			img {
				margin-top: -5px;
				margin-left: -5px;
				width:105px;
			}
		}

		.footer__links {
			order: 2;
			flex-basis:100%;

			text-align: center;
			margin-bottom: 20px;

			@media only screen and (min-width : 768px) {
				order: 3;
				flex-basis: auto;
				text-align: left;
			}

			.footer__link {
				display: block;
				color: #FFFFFF;
				text-decoration: none;
				text-align: center;
				padding: 0;


				@media only screen and (min-width : 768px) {
					display: inline;
					text-align: left;
					padding: 0 10px;
				}
			}

		}
	}
}

