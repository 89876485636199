.container_vineyard {
    margin-right: auto;
    margin-left: auto;
    padding-left:  $gutter-half;
    padding-right: $gutter-half;
    @include clearfix;
    
    @include bp('sm') { width: $container-sm; }
    @include bp('md') { width: $container-md; }
    @include bp('lg') { width: 1440px; }
}
.card_vineyard {
    display: inline-block;
    margin: 10px 5px;
    text-align: center;
}