.shop_table {
    tr {
        display: flex;
    }
    td:first-child {
        flex-grow: 1;
    }
}
.acf-form {
    margin-bottom: $gutter;
}

.woocommerce-MyAccount-content {
    h2 {
        margin-top: 0;
    }
}
