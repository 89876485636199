// Formulaire : Nous rejoindre
.form__content--4 {
  margin-bottom: $gutter;

  //ETAPES
  .steps {
    margin-top: $gutter*2;
    margin-bottom: $gutter/2;
    display: none;  
    @include bp('sm') {
      display: flex;
    }
    .step {
      text-align: center;
      color: $gray-light;
      flex-basis: calc(100%/6);
      max-width: calc(100%/6);

      &.active {
        color: $color-primary;  
      }
  
      &:not(.active){
        .step__img{
          img {
            filter: grayscale(100%) opacity(50%); 
          }
        } 
      }
  
      .step__img{
        margin-bottom: $gutter/2;
        padding: 5px;
        img {
          width: 100%;
          max-width: 70px;
          
        }
      }
      
      .step__num {
        font-weight: bold;
        display: none;
        @include bp('sm') {
          display: block;
        }
      }

      .step__desc {
        display: none;
        @include bp('sm') {
          display: block;
        }
      }
      
    }
  }
  //BAR PROGRESSION
  .gf_progressbar_title {
    
    @include bp('sm') {
      display: none;
    }
  }
  .percentbar_blue {
    background-color: $color-primary;  
  }

  //CHAMPS
  input, textarea {
    background-color: $color-bg;
    border: none;
  }

  .gfield_checkbox {
    display: flex;
    flex-wrap: wrap;
   
    > li {
      flex-basis: 100%;
      max-width: 100%;
      @include bp('xs') {
        flex-basis: 50%;
        max-width: 50%;
      }
      @include bp('md') {
        flex-basis: 25%;
        max-width: 25%;
      }
      .appellations_children {
        padding: 0;
       
      }
    }
   
  }

  //OFFRES
  #field_4_75 {
    .ginput_container {
      background-color: $color-primary;
      padding: $gutter;
      @include bp('md') {
        padding: $gutter $gutter*2;
      }
      @include bp('lg') {
        padding: $gutter*2 $gutter*4;
      }
    }
    .gfield_description {
      background-color: $color-primary;
      .btn {
        display: inline-block;
        margin-left: $gutter;
        margin-bottom: $gutter;
        border-color: white;
        @include bp('md') {
          margin-left: $gutter*2;
        }
        @include bp('lg') {
          margin-left: $gutter*4;
        }
        &:hover {
          border-color: white;
        }
      }
    }
    .gfield_radio {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      > li {
        flex-basis: 100%;
        max-width: 100%;
        background-color: white;
        margin: 0;
        margin-bottom: $gutter;

        @include bp('md') {
          flex-basis: 48%;
          max-width: 48%;
        }
        label {
          margin: 0;
          max-width: 100%;
          width: 100%;
          height: 100%;
        }
      }
      
      input{
        position: absolute;
        clip: rect(0px, 0px, 0px, 0px);   
      }
     
    }
   
  }
}

//global
#gform_4 {
  h2.gsection_title {
    font-weight: 300;
	  font-size: 18px;
    @media only screen and (min-width : 768px) {
      font-size: 24px;
    }
  }
  .gf-half {
    display: inline-block;
    width: 100%;
    @include bp('sm') {
      max-width: 50%;
    }
  }
  .gfield {
    margin-bottom: 5px;
    @include bp('sm') {
      margin-bottom: $gutter;
    }
  }
  .gform_next_button,
  #gform_submit_button_4 {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    color: white;
    padding: 5px $gutter;
  }
  .gform_previous_button {
    background-color: white;
    color: $color-primary;
    border: 1px solid $color-primary;
    padding: 5px $gutter;
  }
}
