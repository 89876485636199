.vineyards {}


.vineyards--home {

	position: relative;
	margin-bottom: 60px;

	.vineyards__title {
		text-align: left;
	}

	.vineyards__item {

		.vineyards__image {
			display: block;
			margin-bottom: 5px;
		}

		.vineyards__label {
			font-family: $font-secondary;
			font-size: 12px;
			color: $font-primary;
			text-align: left;
		}
	}
}


.vineyards--list {

	.vineyards__title {
		margin-top: 25px;
		text-align: left;
	}

	.vineyards__headline {
		font-family: $font-secondary;
		font-size: 16px;
		color: $color-txt2;
		margin-bottom: 20px;
	}

	.vineyards__topfilters {
		display : flex;
		flex-direction : row;
	    flex-wrap : wrap;
	    justify-content : flex-start;
	    align-items : center;
	    margin-bottom: 15px;

		.vineyards__filter {
			font-family: $font-primary;
			font-size: 14px;
			color: $color-txt1;
			background-color: $color-bg;
			padding: 2px 10px;
		}
	}

	.vineyards__leftfilters {
		margin-top: 30px;

		#uwpqsf_id {
			border: none !important;
		}

		.uform_title {
			font-family: $font-secondary;
			background-color: $color-secondary;
			padding: 10px 8px;
			font-size: 14px;
			text-align: left;
			color: #FFFFFF;
			text-transform: uppercase;
		}

		.uwpqsf_class {
			font-weight: 300;
			border-top:solid #FFFFFF 4px;
			background-color: $color-bg;

			span {
				font-family: $font-secondary;
				font-weight: 300;
				text-transform: uppercase;
				margin-top: 10px;
			}

			input[type=checkbox] {
				appearance: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				-ms-appearance: none;
				margin-right: 10px;
			}

			input[type=checkbox] {
				border-radius: 0px;
				height: 15px;
				width: 15px;
				background: #fff;
				border: 1px solid $color-txt1;
			}

			input[type="checkbox"]:checked {
				margin-right: 10px;
				position: relative;
					&:before {
					font-family: FontAwesome;
					content: '\f00c';
					display: block;
					font-size: 13px;
					position: absolute;
				}
			}

			label {
				padding-left: 10px;
				font-size: 14px;
				font-weight: 300 !important;

				span {
					margin: 0;
				}
			}
		}
	}
}



.vineyard--headline {

	cursor: pointer;
	background-color: $color-bg;
	margin-bottom: 25px;

	display : block;
	flex-wrap : nowrap;
    justify-content : flex-end;
    align-items : stretch;

    @media only screen and (min-width : 768px) {
		display : flex;
    }

	.vineyard__image {

		flex : 2;
		height: 175px;
		background-size: cover;

		@media only screen and (min-width : 768px) {
			height: 280px;
		}
	}

	.vineyard__content {

		flex : 1;
		position: relative;
		padding: $padding-content;

		.vineyard__portrait {
			position: absolute;
			border-radius: 50%;
	    	border: solid #FFF 2px;
			width: 60px;
			height: 60px;
	    	top: -30px;
		   	left: 20px;
	    	z-index: 500;

	    	@media only screen and (min-width : 768px) {
				top: 10px;
	   			left: -25px;
			}
		}

		.vineyard__infos {

			display : flex;
		    flex-wrap : nowrap;
		    justify-content : flex-end;
		    align-items : center;
		    margin-bottom: 15px;

			.vineyard__rank {
				font-family: $font-secondary;
				font-size: 15px;
				color: #FFFFFF;
				width: 130px;
				background-color: $color-ternary;
				text-align: center;
				padding: 6px;
				line-height: 15px;

				span {
					font-size: 15px;
					font-weight: 700;
				}
			}
		}

		.vineyard__title {
			display: block;
			font-family: $font-primary;
			color: $color-txt1;
			font-weight: 700;
			margin-top: 50px;

		}

		.vineyard__headline {
			font-family: $font-secondary;
			color: $color-txt2;
			font-size: 16px;
		}
	}
}




.vineyard--list {
	flex-grow:0;

	cursor: pointer;
	border: solid $color-varied 1px;
	margin-bottom: 30px;


	.vineyard__image {
		position: relative;
		height: 175px;
		background-size: cover;
	}

	.vineyard__content {
		position: relative;
		padding: $padding-content;

		.vineyard__portrait {
			position: absolute;
			border-radius: 50%;
	    	border: solid #FFF 2px;
			width: 60px;
			height: 60px; 
	   		top: -30px;
	   		left: 20px;
	    	z-index: 500;
		}

		.vineyard__infos {
			display : flex;
		    flex-wrap : nowrap;
		    justify-content : flex-end;
		    align-items : center;
		    margin-bottom: 15px;

			.vineyard__rank {
				font-family: $font-secondary;
				font-size: 15px;
				color: #FFFFFF;
				width: 130px;

				text-align: center;
				padding: 6px;
				line-height: 15px;

				span {
					font-size: 15px;
					font-weight: 700;
				}
			}

			.vineyard__rank--full {
				background-color: $color-ternary;
			}
		}

		.vineyard__title {
			font-family: $font-primary;
			color: $color-txt1;
			font-weight: 700;
		}

		.vineyard__headline {
			font-family: $font-secondary;
			color: $color-txt2;
			font-size: 16px;
		}
		
	}
}





.vineyard--article {

	.vineyard__header {
		position: relative;
		display: block;
		z-index: 500;

		.vineyard__header-profil {
			float: left;
			border-radius: 50%;
			border: solid #FFF 3px;
			margin-top: -70px;
			margin-left: 0;
			text-align: center;
			width: 120px;

			@media only screen and (min-width : 768px) {
				margin-top: -130px;
				margin-left: 20px;
				width: 240px;
			}
		}

		.vineyard__header-titles {
			margin-left: 180px;

			@media only screen and (min-width : 768px) {
				margin-left: 280px;
			}

			.vineyard__header-title {
				font-family: $font-primary;
				font-size: 28px;
				color: $color-primary;
				line-height: 1.1;
				margin: 0;
			}

			.vineyard__header-localisation {
				font-family: $font-secondary;
				font-weight: 500;
				font-size: 16px;
				color: #a8a8a8;
			}
		}

		.vineyard__header-logo {
			margin: 10px auto 0 auto;

			@media only screen and (min-width : 768px) {
				margin: 55px 0 0 0;
			}
		}
	}


	.vineyard__menu {
		display : flex;
	      flex-wrap : nowrap;
	      justify-content : center;
	      align-items : center;
	      margin-bottom: 15px;

		.vineyard__menu-item {
			position: relative;
			flex-grow: 1;
			padding: 15px;
			text-align: center;
			margin: 0 5px;
		}

		a.vineyard__menu-item--item1 {
			background-color: $color-primary;
			color: #FFF;
			text-decoration: none;

			&:after {
				content: "";
				position: absolute;
				top: 100%;
				left: calc(50% - 12px);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 14px 12px 0 12px;
				border-color: $color-primary transparent transparent transparent;
			}
		}

		.vineyard__menu-item--item2 {
			color: $color-txt2;
			border-bottom: solid $color-varied 4px;
		}

		a.vineyard__menu-item--item2 {
			color: $color-varied;
			font-weight: 500;
			text-decoration: none;
		}

		a.vineyard__menu-item--item2:hover {
			color: $color-ternary;
			border-bottom: solid $color-ternary 4px;
		}
	}


	.vineyard__content {
		position: relative;
		padding: $padding-content;
		margin-bottom: 20px;
		border: solid $color-varied 1px;

		.vineyard__content-title {
			font-weight: 700;
			font-size: 22px;
			margin: 0;
			padding: 0;
		}

		.vineyard__content-toppictos {
			display : flex;
			flex-direction : row;
			flex-wrap:wrap;
 			justify-content : space-between;
			align-items : stretch;

 			.vineyard__content-winetypes {
				display : flex;
				flex-direction : row;
			    flex-wrap : wrap;
			    justify-content : flex-start;
			    align-items : stretch;
				margin:20px 0;
			}

			.vineyard__content-labels {
					display : flex;
					flex-direction : row;
				    flex-wrap : wrap;
				    justify-content : flex-end;
				    align-items : stretch;
					margin:20px 0;
				}


		}



		.vineyard__content-txt {
			font-size:16 px;
			margin-bottom: 30px;
		}

		.vineyard__content-ranking {
			width: 350px;
			display : flex;
		      flex-wrap : nowrap;
		      justify-content : center;
		      align-items : center;
		      padding: $padding-content;
		      margin-bottom: 20px;
		      background-color: $color-bg;

			.vineyard__content-rankingtitle {
				flex-grow:1;
				font-family: $font-secondary;
				font-size: 28px;
				font-weight: 300px;
				color: $color-ternary;
				line-height: 1.1;
			}

			.vineyard__content-rankingrank {
				color: $color-ternary;
				font-size: 24px;

				span {
					font-size: 45px;
				}
			}
		}

		.vineyard__content-services {
			display : flex;
			flex-direction : row;
			flex-wrap : wrap;
			justify-content : flex-start;
			align-items: flex-start;
			padding: $padding-content;
			border: solid $color-secondary 1px;
			margin-bottom: 10px;
		}

		.vineyard__content-farming {
			display : flex;
			flex-direction : row;
			flex-wrap : wrap;
			justify-content : flex-start;
			align-items: flex-start;
			padding: $padding-content;
			border: solid $color-farming 1px;
			margin-bottom: 20px;
		}
	}

	.vineyard__informations {

		position: sticky;
		position: -webkit-sticky;
		top: 100px;

		.vineyard__informations-title {
			font-family: $font-primary;
			color: #FFF;
			font-size: 14px;
			padding: 10px;
			background-color: $color-secondary;
		}

		.vineyard__informations-content {
			padding: $padding-content;
			border: solid $color-varied 1px;

			.vineyard__localisation{
				display: flex;
				justify-content: space-between;
			}

			.vineyard__informations-marker{
				font-size: 14px;
				img {
					margin-left: auto;
    				margin-right: auto;
					height: 45px;
					width: 25px;
				}
			}

			.vineyard__informations-block {
				margin-bottom: 20px;
			}

			.vineyard__informations-info1 {

				font-family: $font-secondary;
				font-size: 14px;
				font-weight: 500;

				span {
					font-family: $font-secondary;
					font-size: 14px;
					font-weight: 700;
				}
			}
			.vineyard__informations-label {

				font-family: $font-secondary;
				font-size: 14px;
				font-weight: 700;
				
			}
			
			.vineyard__paiements,
			.vineyard__socials {
				display: flex;	
				.vineyard__informations-label {
					margin-right: 5px;
					line-height: 32px;
				}
				.vineyard__paiement,
				.vineyard__social {
					margin-right: 5px;	
				}
			}
			.vineyard__paiements{		
				display: flex;	
				.vineyard__informations-label {
					line-height: 50px;
				}
				
			}
			.vineyard__site {
				a {
					word-break: break-word;
				}
			}
			
			.vineyard__informations-info2 {

				p,span,div {
					font-family: $font-secondary;
					font-size: 14px;
					font-weight: 500;
				}
			}

			.vineyard__informations-info3 {

				p,span,div {
					font-family: $font-secondary;
					font-size: 14px;
					font-weight: 500;
					color: $color-secondary;
				}
			}


			.vineyard__informations-block--access {
				border: solid $color-secondary 1px;
				padding: $padding-content;
				margin-bottom: 0px;

				.vineyard__informations-info {
					font-size: 13px;
					color: $color-secondary;

				}
			}
			
			.vineyard__flag {
				display:inline-block;
				margin-right:5px;
			}
			.vineyard__social_network {
				&:hover {
					img {
						opacity: 0.8;
					}
				}
			}
		}
		.vineyard__author {
			p {
				font-size: 14px; 
			}
		}
	}
	
}




@media (min-width:1200px) {
	.abcd {
		position:sticky;
		top:90px;
	}
	.abcd_column {
		column-count: 4;
		column-gap: 20px;
	}
}

@media (min-width:1024px) {
	.abcd_column {
		column-count: 4;
		column-gap: 20px;
	}
}

@media (min-width:767px) {
	.abcd_column {
		column-count: 3;
		column-gap: 20px;
	}
}

.abcd_column {margin-bottom:20px}

nav.abcd {
  background: #fff;
  height: 40px;
  width: 100%;
  z-index: 1;
}
nav.abcd ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: flex;
  margin: 0 auto;
  flex-wrap:wrap
}
nav.abcd ul li {
  display: table-cell;
}
nav.abcd ul li a {
  padding: 10px 20px;
  display: block;
  color: grey;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
nav.abcd ul li a:hover {
  color: #464179;
}
nav.abcd ul .active a {
  color: #464179;
  border-bottom: 3px solid #464179;
}

.abcd_section {margin-bottom: 50px;}