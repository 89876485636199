.formpage {
    margin-top: 30px;
    padding-bottom: 30px;

    .formpage__content {

        .formpage__title {
            font-size: 36px;
            text-align: center;
        }

        .formpage__description {
            font-size: 16px;
            text-align: left;
            margin-top:20px;
            margin-bottom: 20px;

        }

        .formpage__coordinates {
            font-family: $font-secondary;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 40px;

            .formpage__phone {
                font-family: $font-secondary;
                font-size: 24px;
                color: $color-primary;
            }
        }


        .formpage__main {

            background-color:#f5f5f5;
            margin-bottom:20px;

            .formpage__input {
            }
            .gf-half {
                display: inline-block;
                width: 50%;
            }
            .medium {
                width: 100%;
            }

            select {
                padding: 9px;
            }


            .formpage__form {
                margin:30px;

                .gform_body {
                    background-image: #f1f1f1;

                }

                .gfield {
                    padding-right:0;

                }


                .gfield_label {
                    float: left;
                    width: 160px;
                    text-size: 16px;
                    font-weight: 500;
                    text-align: right;

                }

                .ginput_container {
                    margin-left:165px;

                }

                ul {
                    list-style-type: none;
                }

                .gform_footer {
                    text-align: right;
                    margin: 0;
                }

                input{
                    border: solid #eef0ef 1px;
                }

                textarea{
                    border: solid #eef0ef 1px;
                }

                select{
                    border: solid #eef0ef 1px;
                }

                input[type=submit]{
                    height: 35px;
                    color: #FFFFFF;
                    padding: 0 15px;
                    margin:0;
                    background-color: $color-primary;
                    border: solid $color-primary 1px;
                }

                input[type=submit]:hover {
                    background-color: $color-primary-hover;
                    border: solid $color-primary-hover 1px;

                }

                .formpage__obligatory {
                    margin: 20px 30px;
                    font-size: 16px;
                }


            }


            @media only screen and (min-width : 768px) {
                background-color: none;
            }
        }




    }
}