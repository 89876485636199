.posts {
	position: relative;
	margin: 30px 0;

}

.posts--home {
	background-color: $color-bg;

	.posts__title {
		text-align: left;
	}

	.posts__more {
		margin: 25px auto 35px auto;
		text-align: center;

		@media only screen and (min-width : 768px) {
			margin: 25px 0 100px 0;
			text-align: right;
		}
	}

}


.posts--list {
	background-color: #FFF;

	.posts__title {
		text-align: left;
	}

	.posts__more {
		margin: 25px auto 35px auto;
		text-align: center;

		@media only screen and (min-width : 768px) {
			margin: 25px 0 100px 0;
			text-align: right;
		}
	}

}



.post--list {
	cursor: pointer;
	background-color: #FFF;

	.post__image {
		position: relative;
		height: 215px;
		background-position: top center;
		background-size: cover;
	}

	.post__content {
		padding : $padding-content;

		.post__date {
			font-family: $font-secondary;
			font-size: 16px;
			font-weight: 700;
			color: $color-secondary;
			margin-bottom: 15px;
		}

		.post__title {

			margin: 0;

			a {
				color: $color-txt2;
				font-family: $font-primary;
				font-size: 16px;
				font-weight: 700;
			}
		}

		.post__headline {
			font-family: $font-secondary;
			font-size: 16px;
			color: $color-txt2;
			font-size: 1.0;
		}
	}
}

.post--article {

	.post__image {
		position: relative;
		margin: 15px 0;
	}

	.post__content {
		margin-bottom: 15px;

		.post__title {
			font-family: $font-primary;
			color: $color-txt2;
		}

		.post__date {
			font-family: $font-secondary;
			font-size: 16px;
			font-weight: 700;
			color: $color-secondary;
			margin-bottom: 15px;
		}

		.post__txt {
			font-family: $font-secondary;
			font-size: 16px;
			color: $color-txt1;
			margin-bottom: 50px;
		}
	}



	.post__comments {
		label {
			float: left;
			width: 200px;
			text-align: right;
			vertical-align: top;
			font-weight: 500;
			padding-right:10px;

		}

		.responses {
			.blog-comment {
				border:solid $color-varied 1px;
				padding:10px 15px;
				margin-bottom:10px;
			}

		}

	}
}

