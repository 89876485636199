////////////////////////////////////////////////////////////////////////////////
// NEW VARIABLES

// GUTTER

$gutter:                                  3rem;

$gutter-half:                             $gutter / 2;
$gutter-quarter:                          $gutter / 4;
$gutter-eighth:                           $gutter / 8;
$gutter-double:                           $gutter * 2;
$gutter-quad:                             $gutter * 4;
$gutter-octo:                             $gutter * 8;


// LAYOUT COLORS
$color-primary:               #464179 !default; // violet
$color-secondary:             #a78b5b !default; // gold
$color-ternary:               #f57751 !default; // orange

$color-primary-hover:         #24213e !default; // violet foncé
$color-secondary-hover:       #baa27c !default; // gold léger
$color-ternary-hover:         #a74427 !default; // orange foncé

$color-bg:                    #F4F4F4 !default; // light grey
$color-varied:                #CCCCCC !default; // dark grey

// TXT COLORS
$color-txt1:                  #333333; // noir profond
$color-txt2:                  #484848; // gris foncé

$color-farming:               #98c216; // vert

$color-variation-ratio-base:  10% !default;
$color-variation-ratio-more:  20% !default;
$color-variation-ratio-most:  30% !default;

$color-success:               #4cbb6c !default;
$color-warning:               #ff9a52 !default;
$color-danger:                #d73925 !default;
$color-info:                  #3c8dbc !default;

// DATA
$padding-content:             20px;
$padding-button:              22px;

// NAVBAR CONFIGURATION

$header-color-bg:             white;

$navbar-menu-mobile-width:    15em !default;

$adminbar-height-mobile:      4.5rem !default;
$adminbar-height-desktop:     3.2rem !default;

$navbar-height-mobile:        6rem !default;
$navbar-height-desktop:       $navbar-height-mobile !default;
$topbar-height-mobile:        6rem !default;
$topbar-height-desktop:       $topbar-height-mobile !default;

$topbars-total-height:        $navbar-height-mobile + $topbar-height-mobile;

$navbar-btn-size:             4.4rem !default;

$navbar-brand-width:          auto !default;
$navbar-brand-height:         $navbar-btn-size !default;




// DEFAULT BOOTSTRAP VARIABLES OVERRIDE

$brand-primary:           $color-primary;
$brand-secondary:         $color-secondary;
$brand-ternary:           $color-ternary;  // [NEW]

// Links

$link-color:              $brand-secondary;

// TYPOGRAPHY

$font-primary:            "museo", "Times New Roman", Times, serif;
$font-secondary:          "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-family-sans-serif:  "Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       "Lora", Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-base:          16px;
$font-size-large:         ceil(($font-size-base * 1.25));
$font-size-small:         ceil(($font-size-base * 0.85));

$font-size-h1:            floor(($font-size-base * 2.6));
$font-size-h2:            floor(($font-size-base * 2.15));
$font-size-h3:            ceil(($font-size-base * 1.7));
$font-size-h4:            ceil(($font-size-base * 1.25));
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.85));

$line-height-base:        1.5;

$headings-font-family:    inherit;
$headings-font-weight:    700;
$headings-line-height:    1.1;
$headings-color:          inherit;

$border-radius-base:      0px;
$border-radius-large:     1.4142 * $border-radius-base;
$border-radius-small:     2 * $border-radius-base;

$btn-font-weight:         700;
$btn-text-transform:      uppercase;  // [NEW]

$input-bg:                #fff;
$input-border:            #ccc;
$input-color-placeholder: #999;



 $screen-sm:               768px;
 $screen-md:               992px;
 $screen-lg:               1200px;

$grid-columns:            12;
$grid-gutter-width:       30px;
$grid-float-breakpoint:   $screen-sm;

// Common screen sizes, in em unit (1em = 16px, browser default font-size)
//          in em    in px
// 'xxs':   20em,    // 320
// 'xs':    30em,    // 480
// 's':     40em,    // 640
// 'sm':    48em,    // 768
// 'm':     60em,    // 960
// 'ml':    64em,    // 1024
// 'l':     75em,    // 1200
// 'xl':    90em,    // 1440
// 'xxl':   100em,   // 1600
// 'xxxl':  120em    // 1920

$screen-xs:                       30em;    // 480px
$screen-xs-max:              47.9375em;    // 767px
$screen-sm:                       48em;    // 768px 
$screen-sm-max:              61.9375em;    // 991px
$screen-md:                       62em;    // 992px
$screen-md-max:              74.9375em;    // 1199px
$screen-lg:                       75em;    // 1200px


$grid-float-breakpoint: 3000px;

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

$container-tablet:             (720px + $grid-gutter-width);
$container-desktop:            (940px + $grid-gutter-width);
$container-large-desktop:      (1140px + $grid-gutter-width);





////////////////////////////////////////////////////////////////////////////////
// BOOTSTRAP REMOVAL

.fade {
  opacity: 0;
  transition: opacity .15s;
  &.in {
    opacity: 1;
  }
}

.collapse {
  display: none;

  &.in      { display: block; }
}

tr.collapse.in    { display: table-row; }
tbody.collapse.in { display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s, visibility 0.35s;
}
