.search {

	.search__content {
		position: relative;
		display: flex;
		align-items: center;
		justify-content:center;
		flex-wrap : no-wrap;
		text-align: left;
		width: 100%;
		height: 50px;
        background-color: $color-primary;

        @media only screen and (min-width : 768px) {
            background-color: none;
        }

        .btn {
            &:hover {
                color: $color-varied;
                background-color: #FFFFFF;
            }
        }

		.search__input {
			display: inline-block !important;
			flex-basis: calc(100% - 50px);
			line-height: 50px;
			height: 50px;
			border: 1px solid #FFFFFF;
            box-shadow: none;

			@media only screen and (min-width : 768px) {
				flex-basis: calc(100% - 80px);
			}
		}

		.search__btn {
			color: $color-txt1;
			height: 50px;
			flex-basis: 50px;
			padding-left: 10px;
			padding-right: 10px;

			@media only screen and (min-width : 768px) {
				flex-basis: 80px;
				padding-left: 20px;
				padding-right: 20px;
			}
		}

        i.fa-spinner {
            position: absolute;
            z-index: 10;
            top: 16px;
            right: 90px;
            -webkit-animation-name: spin;
            -webkit-animation-duration: 2000ms;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;
            -moz-animation-name: spin;
            -moz-animation-duration: 2000ms;
            -moz-animation-iteration-count: infinite;
            -moz-animation-timing-function: linear;
            -ms-animation-name: spin;
            -ms-animation-duration: 2000ms;
            -ms-animation-iteration-count: infinite;
            -ms-animation-timing-function: linear;
            animation-name: spin;
            animation-duration: 2000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            font-size: 18px;
        }

        .fa-spinner-top {
            right: 20px !important;
        }

        .tt-highlight {
            font-weight: 500;
            color: $color-secondary;

        }
        .tt-dataset {
            font-family: $font-secondary;
            font-size: 14px;
            margin: 0 15px 7px 7px;
        }

        .tt-suggestion {
            font-family: $font-secondary;
            font-size: 18px;
            margin-left: 17px;

        }
	}


	@-ms-keyframes spin {
		from {
			-ms-transform: rotate(0deg);
		} to {
			-ms-transform: rotate(360deg);
		}
	}

}

.search--header {

    padding: 5px 10px;
    background-color: $color-primary;

    @media only screen and (min-width : 768px) {
        background-color: transparent;
    }

	.search__content {
		margin: 12px auto;
	}
}



.search--home {

	background-color: $color-primary;
	padding:10px 0;
    position: relative;

	.search__content {
		margin: 10px auto;

	}
}

.has-warning .twitter-typeahead .tt-input,
.has-warning .twitter-typeahead .tt-hint {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .twitter-typeahead .tt-input:focus,
.has-warning .twitter-typeahead .tt-hint:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}
.has-error .twitter-typeahead .tt-input,
.has-error .twitter-typeahead .tt-hint {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .twitter-typeahead .tt-input:focus,
.has-error .twitter-typeahead .tt-hint:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.has-success .twitter-typeahead .tt-input,
.has-success .twitter-typeahead .tt-hint {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .twitter-typeahead .tt-input:focus,
.has-success .twitter-typeahead .tt-hint:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}
.input-group .twitter-typeahead:first-child .tt-input,
.input-group .twitter-typeahead:first-child .tt-hint {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  width: 100%;
}
.input-group .twitter-typeahead:last-child .tt-input,
.input-group .twitter-typeahead:last-child .tt-hint {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
}
.input-group.input-group-sm .twitter-typeahead .tt-input,
.input-group.input-group-sm .twitter-typeahead .tt-hint {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
select.input-group.input-group-sm .twitter-typeahead .tt-input,
select.input-group.input-group-sm .twitter-typeahead .tt-hint {
  height: 30px;
  line-height: 30px;
}
textarea.input-group.input-group-sm .twitter-typeahead .tt-input,
textarea.input-group.input-group-sm .twitter-typeahead .tt-hint,
select[multiple].input-group.input-group-sm .twitter-typeahead .tt-input,
select[multiple].input-group.input-group-sm .twitter-typeahead .tt-hint {
  height: auto;
}
.input-group.input-group-sm .twitter-typeahead:not(:first-child):not(:last-child) .tt-input,
.input-group.input-group-sm .twitter-typeahead:not(:first-child):not(:last-child) .tt-hint {
  border-radius: 0;
}
.input-group.input-group-sm .twitter-typeahead:first-child .tt-input,
.input-group.input-group-sm .twitter-typeahead:first-child .tt-hint {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group.input-group-sm .twitter-typeahead:last-child .tt-input,
.input-group.input-group-sm .twitter-typeahead:last-child .tt-hint {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.input-group.input-group-lg .twitter-typeahead .tt-input,
.input-group.input-group-lg .twitter-typeahead .tt-hint {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}
select.input-group.input-group-lg .twitter-typeahead .tt-input,
select.input-group.input-group-lg .twitter-typeahead .tt-hint {
  height: 46px;
  line-height: 46px;
}
textarea.input-group.input-group-lg .twitter-typeahead .tt-input,
textarea.input-group.input-group-lg .twitter-typeahead .tt-hint,
select[multiple].input-group.input-group-lg .twitter-typeahead .tt-input,
select[multiple].input-group.input-group-lg .twitter-typeahead .tt-hint {
  height: auto;
}
.input-group.input-group-lg .twitter-typeahead:not(:first-child):not(:last-child) .tt-input,
.input-group.input-group-lg .twitter-typeahead:not(:first-child):not(:last-child) .tt-hint {
  border-radius: 0;
}
.input-group.input-group-lg .twitter-typeahead:first-child .tt-input,
.input-group.input-group-lg .twitter-typeahead:first-child .tt-hint {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group.input-group-lg .twitter-typeahead:last-child .tt-input,
.input-group.input-group-lg .twitter-typeahead:last-child .tt-hint {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.twitter-typeahead {
  width: 100%;
  float: left;
}
.input-group .twitter-typeahead {
  display: table-cell !important;
}
.twitter-typeahead .tt-hint {
  color: #999999;
}
.twitter-typeahead .tt-input {
  z-index: 2;
}
.twitter-typeahead .tt-input[disabled],
.twitter-typeahead .tt-input[readonly],
fieldset[disabled] .twitter-typeahead .tt-input {
  cursor: not-allowed;
  background-color: #eeeeee !important;
}
.tt-dropdown-menu,
.tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 160px;
  width: 100%;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
}
.tt-dropdown-menu .tt-suggestion,
.tt-menu .tt-suggestion {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
}
.tt-dropdown-menu .tt-suggestion.tt-cursor,
.tt-menu .tt-suggestion.tt-cursor,
.tt-dropdown-menu .tt-suggestion:hover,
.tt-menu .tt-suggestion:hover {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
  background-color: $color-primary;
  color: #FFF;
}
.tt-dropdown-menu .tt-suggestion.tt-cursor a,
.tt-menu .tt-suggestion.tt-cursor a,
.tt-dropdown-menu .tt-suggestion:hover a,
.tt-menu .tt-suggestion:hover a {
  color: #262626;
}
.tt-dropdown-menu .tt-suggestion p,
.tt-menu .tt-suggestion p {
  margin: 0;
}

.custom-error {
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 900;
  right: auto;
  background-color: transparent !important;
  padding: 5px 0 0 0 !important;
  border: none !important;
}

.no-location {
  position: relative;
  padding: $gutter;
  width: 100%;
  height: 100%;
  color: white;
  background: #fb3335;

  &:before {
    content:'';
    position: absolute;
    top: -0.5rem;
    left: 2rem;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fb3335;
  }
}