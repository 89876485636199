.hero {
	display : flex;
	flex-wrap : nowrap;
	justify-content : center;
	align-items : center;

	background-size : cover;
	background-position: center center;
	background-repeat : no-repeat;
	background-color:#CCC;


	.hero__headline {
		position: relative;
		width: 100%;
		line-height: 1;

		@media only screen and (min-width : 768px) {
			width: 600px;
		}

		.hero__sentence {
			display: block;
			font-family: $font-primary;
			color: #FFF;
			font-size: 20px;
			text-align: left;
			margin-bottom: 10px;

			@media only screen and (min-width : 768px) {
				font-size: 32px;
			}
		}

		.hero__author {
			display: block;
			margin-top: 20px;
			font-family: $font-primary;
			color : #FFF;
			font-size: 14px;
			line-height: 1.2;
			text-align: left;
			margin-right: 0;

			@media only screen and (min-width : 768px) {
				font-size: 20px;
				margin-right: 20px;
			}
		}
    }


}


.hero--home {
	height : 280px !important;
    min-height : 280px !important;

    @media only screen and (min-width : 768px) {
		height : 586px !important;
    	min-height : 586px !important;
    }

}

.hero--vineyards {
	height : 280px !important;
    min-height : 280px !important;

	@media only screen and (min-width : 768px) {
		height : 410px !important;
	    min-height : 410px !important;
	}
}

.hero--concept {
	background: transparent url('/wp-content/themes/lightwords/dist/img/concept-hero.jpg') no-repeat center;
	background-size: cover;

	height : 280px !important;
    min-height : 280px !important;

	@media only screen and (min-width : 768px) {
		height : 410px !important;
	    min-height : 410px !important;
	}
}

.hero--acces {
	background: transparent url('/wp-content/themes/lightwords/dist/img/visu-acces-domaines.jpg') no-repeat center;
	background-size: cover;

	height : 280px !important;
    min-height : 280px !important;

	@media only screen and (min-width : 768px) {
		height : 500px !important;
	    min-height : 500px !important;
	}
}