.concept {
	position: relative;

	.concept__title {
		font-family: $font-primary;
		font-size : 36px;
		font-weight: 500;
		text-align: left;

		margin-top: 30px;
		margin-bottom: 50px;
	}

	.concept__headline1 {
		font-family: $font-primary;
		font-size: 16px;
		text-align: left;
		color:$color-primary;
		margin-bottom: 30px;
	}

	.concept__headline2 {
		font-family: $font-primary;
		font-size: 16px;
		color:$color-primary;
		text-align: left;
	}

	.concept__guide {
		font-family: $font-primary;
		font-size: 36px;
		color:$color-secondary;
		text-align: center;
		margin:40px 0;
	}


	.concept__recommendations {
		display : flex;
		flex-direction : row;
	    flex-wrap : wrap;
	    justify-content: space-between;

		.concept__recommendation {
			flex: 1 ;
			padding: 20px;
			border: solid $color-varied 1px;
			margin: 30px;

			.concept__recommendation-txt1 {
				text-align: center;
				margin-bottom: 20px;

			}
			.concept__recommendation-txt2 {
				font-weight:700;
				text-align: center;

			}
			.concept__recommendation-txt3 {
				text-align: center;

			}
		}
	}

	.concept__recommendations--last {
		flex: none;
		margin-bottom: 100px;

		.concept__recommendation {
			flex:1;
			margin: 30px;

			img {
				margin-left: auto;
				margin-right: auto;
			}

			@media only screen and (min-width : 768px) {
				flex: none;
				width: 50%;
				margin: 30px auto !important;
			}

		}
	}

}


