/* CONFIG */

@import "variables";

/* VENDOR LIBS */

@import "bootstrap";

/* LIBS */

@import "lib/cdn-imports";

@import "lib/shadows";
@import "lib/base";
@import "lib/type";
@import "lib/colors";
@import "lib/animation";
@import "lib/buttons";
@import "lib/wordstrap";
@import "lib/breakpoints";
@import "lib/fixed-ratio";
@import "lib/flexgrid";
@import "lib/layout";
@import "lib/sticky-header";
@import "lib/nav";
@import "lib/navbar";
@import "lib/ripple";
@import "lib/styleguide";

@import "lib/debug";

@import "lib/foo";

/* APP */
@import "sections/header";
@import "sections/hero";
@import "sections/footer";
@import "sections/search";
@import "sections/post";
@import "sections/vineyard";
@import "sections/offer"; //Formules Adherents
@import "sections/opinion";
@import "sections/formpage";
@import "sections/concept";
@import "sections/boutique";
@import "sections/equipe";
@import "sections/form4";
@import "sections/offres"; //Offre d'adhesion
@import "sections/myaccount";
@import "sections/didomi";
@import "sections/regions";

@import "app";
@import "dopbsp";

/* Carousel */
@import "lib/owl.carousel.min";

@import "plugins";
