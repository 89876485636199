///////////////////////////////////////////////////////////////////////////////
// NAVBAR


.site-header { background-color: $header-color-bg; }

.wrapper--pre-header {}
.wrapper--header {}
.wrapper--post-header {}

///////////////////////////////////////////////////////////////////////////////
// BAR WRAPPERS


.bar-wrapper {
  // Bar content gutter
  padding-top: ($navbar-height-mobile - $navbar-btn-size) / 2;
  padding-bottom: ($navbar-height-mobile - $navbar-btn-size) / 2;

  // Gives flexbox behavious to bars
  display: flex;
  flex-wrap: wrap;
  > * { flex: 0 0 auto; }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

// A movable div that pushes neighbors on the sides
.bar-filler { flex: 1; }


///////////////////////////////////////////////////////////////////////////////
// TOPBAR

.topbar {
  overflow: hidden;
  height: $topbar-height-mobile;
  // border-top: $topbar-height-mobile solid $header-color-bg;
  // padding-top: $topbar-height-mobile;
  transition: height 0.5s $ease-bump; // TODO: use accellerated transform

  .topbar-menu {
    margin-right: $gutter-half;
  }

  .topbar-search {
    flex-basis: 100%;
    @include bp('expanded') {
      flex-basis: auto;
    }
  }
}

// Topbar on mobile - toggles visibility
@include bp('expanded', true) {
  .site-header {
    transform: translate3d(0, - $topbar-height-mobile, 0);
    transition: transform 0.5s $ease-bump;

    /*html*/.topbar-expanded & {
      transform: translate3d(0, 0, 0);
    }
  }
}

// SEARCHFORM

.bar-wrapper .searchform {
  position: relative;

  input[type="text"] {
    height: $navbar-btn-size;
    line-height: $navbar-btn-size;
    padding-right: 12rem;
  }

  input[type="submit"] {
    position: absolute;
    top: 0.1rem;
    right: 0.1rem;
    border: 0;
    height: $navbar-btn-size - 0.2rem;
    color: $gray-light;
    font-weight: normal;
    text-transform: uppercase;
  }
}


///////////////////////////////////////////////////////////////////////////////
// NAVBAR

.navbar { }


///////////////////////////////////////////////////////////////////////////////
// NAVBAR HEADER

.navbar-header {
  margin-left: -$gutter-half;
}

@include bp('expanded') {
  .navbar-menu { margin-right: - $gutter; }
  .topbar-search { margin-right: - $gutter-half; }
  .topbar-menu { margin-left: - $gutter-half; }
}

// NAVBAR BRAND

.navbar-brand {
  display: block;
  height: $navbar-brand-height;
  padding-left: $gutter-half;
  padding-right: $gutter-half;
  font-size: $nav-fontsize;
  line-height: $navbar-brand-height;
  text-transform: uppercase;
  color: $gray;

  .logo {
    vertical-align: middle;
    fill: $gray;
    transition: transform 0.25s;
  }

  &:hover {
    color: $gray-darker;
    .logo {
      fill: $gray-darker;
      transform: scale3d(1.2, 1.2, 1);
    }
  }

}


///////////////////////////////////////////////////////////////////////////////
// NAVBAR FOOTER

.navbar-footer {
  margin-left: $gutter-half;
  font-size: 0; // removes space from inline buttons
}


// NAVBAR BUTTONS

.btn-toggle {
  padding: 10px;
  border: 0;
  min-width:$navbar-btn-size;
  height:$navbar-btn-size;
  font-size: 1.6rem;
  background-color: transparent;
  color: $gray-light;
  transition: color 0.25s;
  vertical-align: top;

  &:focus {
    outline: 0.1rem solid rgba(255,255,255,1);
  }

  // Gutter between buttons + left spacing
  margin-left: $gutter-quarter;

  // Bring toggle btns back to foreground (in case of overlay/offscreen panel)
  position: relative;
  z-index: +1;
}


///////////////////////////////////////////////////////////////////////////////
// NAVBAR MENU

// COMMON STYLES

.menu-primary,
.menu-secondary {

  // Menu items are common dy default
  .menu-item {

    text-align: center;

    // Space between items when stacked
    margin-bottom: $gutter-quarter;

    @include bp('expanded') {
      margin-bottom: 0;
      margin-left: $gutter-half;
    }

    > a {
      display: block;
      min-height: 4.4rem;
      padding-left: $gutter-half;
      padding-right: $gutter-half;
      color: black;
      // Flex overkill vertical center
      display: flex;
      flex-direction: column;
      justify-content: center;

      // Extra span for advanced styling (aka vertical center)
      span:first-child { // don't catch submenus carret span
        flex: 0 0 auto;
        line-height: 1;
        padding: $gutter-quarter 0;
      }
    }

    &.current-menu-item {
      a {
        background: $brand-secondary;
        color: white;
      }
    }

    > .dropdown-menu {
      // reset lists
      list-style-type: none;
      padding-left: 0;

      .menu-item {
        > a {
          @include bp('expanded', true) {
            padding-left: $gutter;
          }
        }
      }

    }
  }
}

// HAMBURGER ANIMATED ICON

$hamburger-height: 2.4rem;
$hamburger-height-bars: $hamburger-height / 8;
$hamburger-color: $gray-light;

.hamburger-wrapper {
  float: left;
	height: $hamburger-height;
	width: $hamburger-height;
	text-align: center;
	position: relative;

	&:hover {
		.hamburger,
		.hamburger:before,
		.hamburger:after {
			background-color: $gray-lighter;
		}
	}
}

.hamburger,
.hamburger:before,
.hamburger:after {
	display: block;
	height: $hamburger-height-bars;
	width: 100%;
	background-color: $hamburger-color;
	transform: rotate(0deg);
  transition: transform 0.75s $ease-bump,
  background-color 0.25s;
}

.hamburger:before,
.hamburger:after {
	content: '';
	position: absolute;
}

.hamburger {
	position: relative;
	top: ($hamburger-height / 2 - $hamburger-height-bars / 2);

	&:before { top: ($hamburger-height * 3 / 8); }
	&:after {  top: (- $hamburger-height * 3 / 8); }
}

.navbar-expanded  {

  .hamburger-wrapper {

    &:hover {
  		.hamburger {
        background-color: transparent;
      }
  		.hamburger:before,
  		.hamburger:after {
  			background-color: $gray-darker;
  		}
  	}
  }

	.hamburger {
		background-color: rgba(255, 255, 255, 0.0);
		transform: rotate(-90deg);

		&:after {
			transform: translateY($hamburger-height * 3 / 8) rotate(-135deg);
		}

		&:before {
			transform: translateY(- $hamburger-height * 3 / 8) rotate(-45deg);
		}

	}
}


/////////////////////////////////////////////////////////////////////////////
// MOBILE MENU
.navbar-menu {

  // MOBILE MENU

  // Pain in the ass to reset mobile navbar styles on larger screens
  // So for once, we allow MOBILE ONLY styles max-width @mq here!
  @include bp('max', true) {
    display: block !important;
    position: fixed;
    right: 0;
    top: 0;
    left:0;
    bottom:0;
    transform: translate3d(0, -100%, 0);
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s;
    background-color: $header-color-bg; // TODO remove or smarter color
    z-index: 2000;
    

    @media only screen and (min-width : 768px) {
      left: auto;
      width: 300px;
      transform: translate3d(300px, 0, 0);
    }


    &.navbar-expanded {
      // Move the navbar into viewport
      transform: translate3d(0, 0, 0);
      overflow: auto;
      @include shadow(5);
    }


    > .menu-primary {
      // Give nav some air
      margin: 5rem 0;
      // Allow vertical scroll
      // overflow-y: auto; // TODO
    }

    // Generated label
    &:before {
      content: "MENU";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      line-height: $navbar-height-mobile;
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  //DESKTOP MENU

  // Desktop ONLY styles
  @include bp('expanded') {

    @include clearfix;
    margin-left: $gutter-half;


    //   DESKTOP INLINE DROPDOWN SUBMENU

    // TODO + dropdown JS

    .dropdown {

      .dropdown-menu {
        background-color: $header-color-bg;
        position: fixed;
        width: 100vw;
        left: 0;
        top: $navbar-height-desktop + $topbar-height-desktop;
        display: flex;
        justify-content: center;
        box-shadow: none;
        @include shadow(1);
        transform: scale3d(1, 0, 1);
        transform-origin: 50% 0 0;
        overflow: hidden;
        transition: transform 0.5s $ease-bump;

        /*html*/.admin-bar & {
          top: $navbar-height-desktop + $topbar-height-desktop + $adminbar-height-desktop;
        }

        > .menu-item {
          a {
          }
        }
      }

      &.open {  // TODO: use .expanded instead

        .dropdown-menu {
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }

} // end of .navbar-menu


///////////////////////////////////////////////////////////////////////////////
// SECONDARY MENU

.menu-secondary {

  @include bp('expanded') {
    // By default, same as .menu-primary
  }
}



///////////////////////////////////////////////////////////////////////////////
// OVERLAY

@include bp('expanded', true) {

  body.navbar-expanded {
    overflow: hidden;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);

    transform: translate3d(-100%, 0, 0);
    opacity: 0;
    transition: opacity 0.5s, transform 0.25s;

    &.navbar-expanded {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}
